<template>
  <v-container fluid pa-0
    ><ErrorMessage
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="goToTicket"
    />
  </v-container>
</template>
<script>
import ErrorMessage from "../molecules/ErrorMessage.vue";
export default {
  name: "AccessDenied",
  components: { ErrorMessage: () => import("../molecules/ErrorMessage.vue") },
  data() {
    return {
      errorCode: "403",
      errorMessage: "",
    };
  },
  methods: {
    goToTicket() {
      this.$router.push("/tickets").catch();
    },
  },
};
</script>
